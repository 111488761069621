import { gsap, Power3 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import MusicController from '../controllers/MusicController';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import { Videos } from '../_app/cuchillo/components/Videos';
// import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';

export default class PlayerVideo {
    container;
    // isShow = false;
    isReady = false;
    isPlaying = false;

    _video;
    // _timer;
    // _title;
    // _progress;
    // _controls;
    _layer;

    // _progressThumb;
    // _progressTrack;

    // _idTimer;

    // _callMove = () => {
    //   if (this.isPlaying) this.showInterface();
    // };

    get video() {
        return this._video;
    }

    constructor(__container) {
        this.container = __container;
        this._video = GetBy.selector('video', this.container)[0];
        // this._timer = GetBy.class('__timer', this.container)[0];
        // this._title = GetBy.class('__title', this.container)[0];
        // this._controls = GetBy.class('__controls', this.container)[0];
        // this._progress = new Scrollbar(
        //   GetBy.class('__progress', this.container)[0]
        // );

        this.setup();
    }

    setup() {
        // console.log('SETUP VIDEO PLAYER FULL');

        // setTimeout(() => {
        //   this._progress.resize();
        // }, 100);
        // PROGRESS
        // this._progress.onChange = (__progress) => {
        //   this._video.currentTime = this._video.duration * __progress;
        // };

        //CONTROLS
        // GetBy.class('__play', this.container)[0].addEventListener(
        //   Basics.clickEvent,
        //   () => {
        //     this.play();
        //   }
        // );

        // GetBy.class('__pause', this.container)[0].addEventListener(
        //   Basics.clickEvent,
        //   () => {
        //     this.pause();
        //   }
        // );

        // GetBy.class('__muted', this.container)[0].addEventListener(
        //   Basics.clickEvent,
        //   () => {
        //     this.muted();
        //   }
        // );

        // GetBy.class("__full", this.container)[0].addEventListener(Basics.clickEvent, () => {
        //   this.fullscreen();
        // });

        //MOVE
        // this.container.addEventListener(Basics.moveEvent, this._callMove);

        //FULLSCREEN
        this._video.addEventListener('webkitendfullscreen', () => {
            setTimeout(() => {
                if (this._video.muted) this.muted();
                this.play();
            }, 500);
        });
    }

    load() {
        //VIDEO
        this._video.addEventListener('canplaythrough', () => {
            this.isReady = true;
            this.loaded();
        });
    }

    loaded() {
        console.log(this.container);
        gsap.to(this.container, { alpha: 1, duration: 0.4, ease: Power3.easeIn });
    }

    muted(__isMuted = !this._video.muted) {
        // if (__isMuted) {
        //   this._controls.classList.add('--muted');
        // } else {
        //   this.hideInterface(true);
        //   this._controls.classList.remove('--muted');
        // }
        this._video.muted = __isMuted;
    }

    play() {
        if (!this.isReady || !this.isPlaying) {
            // this.hideInterface(true);
            // this._controls.classList.remove('--pause');
            // this._controls.classList.add('--play');
            this._video.play();
            this.isPlaying = true;
        }
    }

    pause() {
        if (this.isPlaying) {
            this._video.pause();
            // this.hideInterface();
            // this._controls.classList.remove('--play');
            // this._controls.classList.add('--pause');
            this.isPlaying = false;
        }
    }

    stop() {
        this._video.pause();
        gsap.to(this.container, { alpha: 0, duration: 0.4, ease: Power3.easeOut });
    }

    fullscreen() {
        if (this._video.muted) this.muted();
        this._video.webkitEnterFullscreen();
    }

    // showInterface(__force) {
    //   document.body.classList.add(
    //     __force ? '__showInterfaceForce' : '__showInterface'
    //   );

    //   if (!__force) {
    //     clearTimeout(this._idTimer);
    //     this._idTimer = setTimeout(() => {
    //       this.hideInterface();
    //     }, 1500);
    //   }
    // }

    // hideInterface(__force) {
    //   document.body.classList.remove(
    //     __force ? '__showInterfaceForce' : '__showInterface'
    //   );
    // }

    // changeTime(__time) {
    //   // this.showInterface();

    //   if (__time > this._video.duration) {
    //     __time = __time - this._video.duration;
    //   } else if (__time < 0) {
    //     __time = this._video.duration - __time;
    //   }

    //   this._video.currentTime = __time;
    // }

    // loop() {
    // if (this.isReady) {
    // const timeRendered = this.secondsToPlayerTime(
    //   this._video.duration - this._video.currentTime
    // );
    // this._timer.textContent = timeRendered;
    // this._progress.update(this._video.currentTime / this._video.duration);
    // }
    // }

    // secondsToPlayerTime(__sec) {
    //   const MINS = Math.floor(__sec / 60);
    //   const SECS = Math.floor(__sec - MINS * 60);
    //   const CENTS = Math.floor((__sec - SECS - MINS * 60) * 100);

    //   const sMINS = MINS < 10 ? '0' + MINS : MINS.toString();
    //   const sSECS = SECS < 10 ? '0' + SECS : SECS.toString();
    //   const sCENTS = CENTS < 10 ? '0' + CENTS : CENTS.toString();

    //   return sMINS + ':' + sSECS + ':' + sCENTS;
    // }

    show() { }

    hide() { }

    dispose() {
        // clearTimeout(this._idTimer);
        // this.container.removeEventListener(Basics.moveEvent, this._callMove);

        this.container = null;
        // this.isShow = null;
        this.isReady = null;

        this._video = null;
        // this._timer = null;
        // this._title = null;
        // this._progress = null;
        // this._controls = null;

        // this._progressThumb = null;
        // this._progressTrack = null;

        // this._idTimer = null;
    }
}