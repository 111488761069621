import DebugPane from "../3D/DebugPane";
import { GetBy } from "../_app/cuchillo/core/Element";
import { Sizes } from "../_app/cuchillo/core/Sizes";
import InterfaceCanvas from "../_app/cuchillo/layout/InterfaceCanvas";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from "../_app/cuchillo/utils/Maths";

export const WAVE_SETTINGS = {
    frequency: 0.25,
    scrollSpeed: 0.009
}

export default class GUIController {
    static canvas = InterfaceCanvas.canvas || document.createElement('canvas');
    static ctx = InterfaceCanvas.ctx || this.canvas.getContext('2d');
    static phase = 0;
    static isDrawing = false;
    static dom;
    static limits = { x1: 0, x2: 0, y1: 0, y2: 0 };
    static x;
    static y;
    static width = 0;
    static height = 0;
    static amplitude = 0;

    static init() {
        this.dom = GetBy.id('wave-container');
        this.resize();
    }

    static start() {
        this.isDrawing = true;
        this.loop();
    }

    static stop() {
        this.isDrawing = false;
    }

    static hide() {
        // TODO: Lerp value on hide
        this.phase = 0;
    }

    static loop() {
        if (!this.isDrawing) return;

        this.phase = Scroll.engine.position * WAVE_SETTINGS.scrollSpeed;

        this.drawSinWave();
    }

    static drawSinWave() {
        const containerHeight = this.limits.y2 - this.limits.y1;

        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.beginPath();

        const sp = Maths.clamp(Math.abs(Scroll.speed * 0.001), 0, .09);
        const freqSp = (WAVE_SETTINGS.frequency / window.devicePixelRatio - sp);
        this.amplitude = Maths.lerp(this.amplitude, freqSp, 0.08);

        for (let x = this.limits.x1; x <= this.limits.x2; x += 1) {
            const relativeX = x - this.limits.x1;
            const amplitude = containerHeight / 2 * Math.sin(this.amplitude * relativeX + this.phase);
            const y = this.limits.y1 + containerHeight / 2 + amplitude;
            this.ctx.lineTo(x, y);
        }

        this.ctx.strokeStyle = '#c24a2f';
        this.ctx.stroke();
    }
    S
    static resize() {
        const rect = this.dom.getBoundingClientRect();

        this.x = rect.x * window.devicePixelRatio;
        this.y = rect.y * window.devicePixelRatio;
        this.width = rect.width * window.devicePixelRatio;
        this.height = rect.height * window.devicePixelRatio;

        this.limits.x1 = this.x;
        this.limits.x2 = this.x + this.width;
        this.limits.y1 = this.y;
        this.limits.y2 = this.y + this.height;

        this.drawSinWave();
    }

    static dispose() {
        this.stop();
    }
}
