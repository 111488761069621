import { MeshBasicMaterial, TextureLoader, Vector3, PlaneGeometry, BoxGeometry, DoubleSide, MeshMatcapMaterial } from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

export const TEXTURE_LOADER = new TextureLoader();
export const OBJ_LOADER = new OBJLoader();
export const GLTF_LOADER = new GLTFLoader();
export const DRACO_LOADER = new GLTFLoader();
DRACO_LOADER.setDRACOLoader(dracoLoader);
export const FONT_LOADER = new FontLoader();

export const CENTER = new Vector3();

export const SETTINGS = {
    opacity: 1,
    fov: 50,
    near: 1,
    far: 15000,
    factor: 20,
    camera2D: true,
    cameraPos: new Vector3(0, 0, 1500),
    lookAt: CENTER,
    controls: false,
    clearColor: '#1F2222',
    clearColorAlpha: 0,
    noiseIntensity: 1.130,
    mouseX: 15.46,
    mouseY: 9.23,
    mouseLerp: .04,
    mouseLerpY: .035,
    posX: 25,
    posY: 20,
    posZ: 80,
    cameraLookAtLerp: .062,
    cameraLerp: .04,
    groupRotation: false,
    cameraRotation: false,
    controls: false
}

// export const RENDER_TARGET_SETTINGS = {
//     mixValue: 0,
//     scale: 13.39,
//     smoothness: .25,
//     render: true,
//     renderAlt: true
// }

export const ANIMATION_SETTINGS = {
    timeScale: 2,
}

// export const LIGHT_SETTINGS = {
//     color: '#F1B6FF',
//     angle: 9,
//     intensity: 1,
//     maxDistance: 2000,
//     penumbra: .78,
//     decay: 20,
//     visible: true,
//     lerp: .065
// }

export const MATCAP_MATERIAL = new MeshMatcapMaterial();
export const BASIC_MATERIAL = new MeshBasicMaterial({ color: 0x0000FF, side: DoubleSide });
export const BOX_GEOMETRY = new BoxGeometry();
export const PLANE_GEOMETRY = new PlaneGeometry();
