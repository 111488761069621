import { Color, PlaneGeometry, ShaderMaterial } from "three";

const RIPPLE_VERTEX = `
    #define PI 3.14159265359

    uniform float u_time;
    uniform float u_pointsize;
    uniform float u_noise_amp_1;
    uniform float u_noise_freq_1;
    uniform float u_spd_modifier_1;
    uniform float u_noise_amp_2;
    uniform float u_noise_freq_2;
    uniform float u_spd_modifier_2;
    varying vec2 vUv;

    // 2D Random
    float random (in vec2 st) {
        return fract(sin(dot(st.xy,
                            vec2(12.9898,78.233)))
                    * 43758.5453123);
    }

    // 2D Noise based on Morgan McGuire @morgan3d
    // https://www.shadertoy.com/view/4dS3Wd
    float noise (in vec2 st) {
        vec2 i = floor(st);
        vec2 f = fract(st);

        // Four corners in 2D of a tile
        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));

        // Smooth Interpolation

        // Cubic Hermine Curve.  Same as SmoothStep()
        vec2 u = f*f*(3.0-2.0*f);
        // u = smoothstep(0.,1.,f);

        // Mix 4 coorners percentages
        return mix(a, b, u.x) +
                (c - a)* u.y * (1.0 - u.x) +
                (d - b) * u.x * u.y;
    }

    mat2 rotate2d(float angle){
        return mat2(cos(angle),-sin(angle),
                  sin(angle),cos(angle));
    }

    void main() {
        vUv = uv;

        gl_PointSize = u_pointsize;

        vec3 pos = position;
        // pos.xy is the original 2D dimension of the plane coordinates
        pos.z += noise(pos.xy * u_noise_freq_1 + u_time) * u_noise_amp_1;
        // add noise layering
        // minus u_time makes the second layer of wave goes the other direction
        pos.z += noise(rotate2d(PI / 4.) * pos.yx * u_noise_freq_2 - u_time * 0.6) * u_noise_amp_2;

        vec4 mvm = modelViewMatrix * vec4(pos, 1.0);
        gl_Position = projectionMatrix * mvm;
    }
`;

const RIPPLE_FRAGMENT = `
    #ifdef GL_ES
    precision mediump float;
    #endif

    #define PI 3.14159265359
    #define TWO_PI 6.28318530718

    uniform vec2 u_resolution;
    uniform float u_transparency; 
    uniform vec3 u_color1; // Primer color del degradado
    uniform vec3 u_color2; // Segundo color del degradado

    void main() {
        // Normaliza las coordenadas del fragmento entre -1 y 1
        vec2 st = gl_FragCoord.xy / u_resolution.xy;
        vec2 pos = (st) * 2.0 - 1.0;

        // Calcula la distancia del fragmento al centro
        float dist = length(pos) / u_transparency;

        vec3 color = mix(u_color1, u_color2, max(st.x, st.y));
        float transparency = 1.0 - smoothstep(0.0, 1.0, dist);

        // Asigna el color resultante al fragmento
        gl_FragColor = vec4(color, transparency);
    }
`;

export const RIPPLE_SETTINGS = {
    rotX: -55,
    rotY: 0,
    rotZ: 45,
    u_transparency: 0,
    u_pointsize: 2.0,
    // wave 1
    u_noise_freq_1: 6.52,
    u_noise_amp_1: 20,
    // wave 2
    u_noise_freq_2: 6.52,
    u_noise_amp_2: 50,
}

const RIPPLE_UNIFORMS = {
    u_time: { value: 3.0 },
    u_mouse: {
        value: {
            x: 0.0,
            y: 0.0
        }
    },
    u_resolution: {
        value: {
            x: window.innerWidth * window.devicePixelRatio,
            y: window.innerHeight * window.devicePixelRatio
        }
    },
    u_color1: { value: new Color('#35BE63') },
    u_color2: { value: new Color('#1DA594') },
    u_transparency: { value: RIPPLE_SETTINGS.u_transparency },
    u_pointsize: { value: RIPPLE_SETTINGS.u_pointsize },
    // wave 1
    u_noise_freq_1: { value: 0 },
    u_noise_amp_1: { value: 0 },
    // wave 2
    u_noise_freq_2: { value: 0 },
    u_noise_amp_2: { value: 0 },
};

export const RIPPLE_GEOMETRY = new PlaneGeometry(1, 1, 128, 128);
export const RIPPLE_MATERIAL = new ShaderMaterial({
    uniforms: RIPPLE_UNIFORMS,
    vertexShader: RIPPLE_VERTEX,
    fragmentShader: RIPPLE_FRAGMENT,
    transparent: true
});
