/**
 * BASS: 20, 40
 * LOW MID: 140, 400
 * MID: 400, 2600
 * HIGH MID: 2600, 5200
 * HIGH: 5200, 14000
 */

export default class MusicAnalyzer {
    context;
    analyser;
    frequencyData;

    get instance() {
        return this.analyser;
    }

    constructor(context) {
        this.context = context;
        this.analyser = context.createAnalyser();
        this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
    }

    connect(destination) {
        this.analyser.fftSize = 256;
        this.analyser.connect(destination);
    }

    analyzeMusic() {
        this.analyser.getByteFrequencyData(this.frequencyData);

        const totalBins = this.analyser.frequencyBinCount;
        const binSize = this.context.sampleRate / totalBins;
        const binsPerOctave = 12;

        const bassFrequencyRange = [20, 40]; // Frecuencias graves
        const lowMidFrequencyRange = [140, 400]; // Frecuencias medias
        const midFrequencyRange = [400, 2600]; // Frecuencias agudas
        const midTrebleFrequencyRange = [2600, 5200]; // Frecuencias agudas
        const trebleFrequencyRange = [5200, 14000]; // Frecuencias agudas
        // const highFrequencyRange = [8000, 20000]; // Frecuencias altas
        // const vocalFrequencyRange = [100, 4000]; // Frecuencias vocales (aproximado)
        // const harmonicFrequencyRange = [100, 20000]; // Frecuencias armónicas

        const bassBins = this.calculateBinsInRange(bassFrequencyRange, binSize, binsPerOctave);
        const lowMidBins = this.calculateBinsInRange(lowMidFrequencyRange, binSize, binsPerOctave);
        const midBins = this.calculateBinsInRange(midFrequencyRange, binSize, binsPerOctave);
        const midTrebleBins = this.calculateBinsInRange(midTrebleFrequencyRange, binSize, binsPerOctave);
        const trebleBins = this.calculateBinsInRange(trebleFrequencyRange, binSize, binsPerOctave);
        // const highBins = this.calculateBinsInRange(highFrequencyRange, binSize, binsPerOctave);
        // const vocalBins = this.calculateBinsInRange(vocalFrequencyRange, binSize, binsPerOctave);
        // const harmonicBins = this.calculateBinsInRange(harmonicFrequencyRange, binSize, binsPerOctave);

        // Calcular la intensidad de cada rango de frecuencia
        const bassIntensity = this.calculateIntensity(bassBins);
        const lowMidIntensity = this.calculateIntensity(lowMidBins);
        const midIntensity = this.calculateIntensity(midBins);
        const midTrebleIntensity = this.calculateIntensity(midTrebleBins);
        const trebleIntensity = this.calculateIntensity(trebleBins);
        // const highIntensity = this.calculateIntensity(highBins);
        // const vocalIntensity = this.calculateIntensity(vocalBins);
        // const harmonicIntensity = this.calculateIntensity(harmonicBins);

        return {
            bass: bassIntensity,
            lowMid: lowMidIntensity,
            mid: midIntensity,
            midTreble: midTrebleIntensity,
            treble: trebleIntensity,
            // high: highIntensity,
            // vocal: vocalIntensity,
            // harmonic: harmonicIntensity
        };
    }

    static detectKick() {
        const bassIntensityThreshold = 0.5; // Umbral de intensidad de graves para detectar un golpe de bombo
        const bassIntensity = this.analyzeMusic().bassIntensity;

        return bassIntensity > bassIntensityThreshold
    }

    calculateBinsInRange(range, binSize, binsPerOctave) {
        const [startFreq, endFreq] = range;
        const startBin = Math.floor(startFreq / binSize);
        const endBin = Math.ceil(endFreq / binSize);
        const bins = [];

        for (let i = startBin; i < endBin; i++) {
            bins.push(i);
        }

        return bins;
    }

    calculateIntensity(bins) {
        let totalIntensity = 0;

        for (const bin of bins) {
            totalIntensity += this.frequencyData[bin];
        }

        // Normalizar la intensidad para que esté en un rango de 0 a 1
        const normalizedIntensity = totalIntensity / (bins.length * 255);
        return normalizedIntensity;
    }
}
