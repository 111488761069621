import gsap from "gsap/gsap-core";
import { Basics, isMobile } from "../_app/cuchillo/core/Basics";
import { GetBy } from "../_app/cuchillo/core/Element";
import MusicAnalyzer from "./MusicAnalyzer";
import EventDispatcher from "../_app/cuchillo/core/EventDispatcher";

export default class MusicController {
    static songs = [];
    static currentSongIndex = -1;
    static isPlaying = false;
    static audioElement = new Audio();
    static audioContext = new AudioContext();
    static analyser;
    static volume;
    static progressName;
    static progressTotal;
    static progressThumb;
    static remainingTime;

    static ON_PLAY = 'onplay';
    static ON_STOP = 'onstop';

    static async init() {
        this.songs = CMS_SONGS;
        this.audioElement.crossOrigin = 'anonymous';
        this.preloadSongs();

        this.volume = isMobile ? GetBy.id('VolumeMobile') : GetBy.id('Volume');
        this.volume.addEventListener(Basics.clickEvent, () => {
            this.toggle();
        });

        this.analyser = new MusicAnalyzer(this.audioContext);

        this.cb = () => {
            this.next();
            this.audioElement.removeEventListener('ended', this.cb);
        }

        if (!isMobile) {
            this.progressTotal = GetBy.id('ProgressTotal');
            this.progressTotal.innerHTML = '00' + CMS_SONGS.length;
            this.progressName = GetBy.id('ProgressName');
            this.progressThumb = GetBy.id('ProgressThumb');
            this.remainingTime = GetBy.id('RemainingTime');
        }
    }

    static async preloadSongs() {
        for (const song of this.songs) {
            await this.loadSong(song.url);
        }
    }

    static async loadSong(url) {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.src = url;
            audio.preload = 'auto';
            audio.addEventListener('canplaythrough', () => {
                audio.pause();
                resolve();
            });
            audio.addEventListener('error', reject);
        });
    }

    static play() {
        this.isPlaying = true;
        this.volume.classList.add('--active');
        this.next();
        EventDispatcher.dispatchEvent(MusicController.ON_PLAY);
    }

    static stop() {
        this.audioElement.pause();
        this.isPlaying = false;
        this.volume.classList.remove('--active');
        if (!isMobile) gsap.set(this.progressThumb, { scaleX: 0 });
        EventDispatcher.dispatchEvent(MusicController.ON_STOP);
    }

    static toggle() {
        if (this.isPlaying) {
            this.stop();
        } else {
            this.play();
        }
    }

    static next() {
        if (this.currentSongIndex < this.songs.length - 1) {
            this.currentSongIndex++;
        } else this.currentSongIndex = 0;

        this.playCurrentSong();
    }

    static previous() {
        if (this.currentSongIndex > 0) {
            this.currentSongIndex--;
        } else this.currentSongIndex = this.songs.length - 1;
        this.playCurrentSong();
    }

    static playCurrentSong() {
        const song = this.songs[this.currentSongIndex];
        this.audioElement.src = song.url;
        this.audioElement.play();
        this.audioElement.addEventListener('ended', this.cb);

        if (!isMobile) {
            this.progressName.innerHTML = 'Track 00' + (this.currentSongIndex + 1);
            this.remainingTime.innerText = this.formatTime(this.audioElement.duration);
        }

        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }

        if (!this.audioElement.sourceNode) {
            const source = this.audioContext.createMediaElementSource(this.audioElement);
            source.connect(this.analyser.instance);
            this.audioElement.sourceNode = source;
        }

        this.analyser.connect(this.audioContext.destination);
    }

    static getProgress() {
        if (this.isPlaying) {
            return this.audioElement.currentTime / this.audioElement.duration;
        } else {
            return 0;
        }
    }

    static analyzeMusic() {
        return this.analyser.analyzeMusic();
    }

    static formatTime(timeInSeconds) {
        if (isNaN(timeInSeconds)) timeInSeconds = 0;

        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    static loop() {
        if (!this.isPlaying || isMobile) return;

        gsap.set(this.progressThumb, { scaleX: this.getProgress() });

        const remainingTime = this.audioElement.duration - this.audioElement.currentTime;
        this.remainingTime.innerText = this.formatTime(remainingTime);
    }
}
