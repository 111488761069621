import gsap, { Power2 } from 'gsap';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import MasonryLayout from '../component/MasonryLayout';

class ScrollItem__WidgetGallery extends VScroll_Item {
  masonry;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (!isMobile) {
      this.masonry = new MasonryLayout('__holder', '__img')
    }

    this.onShow = () => {
    };
    this.onHide = () => {
    };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  // dispose () {
  //   super.dispose();
  // }

  resize() {
    super.resize();

    if (!isMobile) this.masonry.resize();
  }
}

Scroll._registerClass('widget-gallery', ScrollItem__WidgetGallery);