import gsap, { Power2, Power3 } from "gsap";

import _Header from '../_app/cuchillo/layout/Header';
import GUIController from "../controllers/GUIController";

export default class Header extends _Header {
  static isShow = false;
  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static show(__call) {
    if (this.isShow) return;

    this.isShow = true;
    gsap.to(this.container, {
      opacity: 1, duration: 1, delay: .5, ease: Power2.easeOut, onComplete: () => {
        GUIController.start();
      }
    });
    gsap.to(this.footer, { opacity: 1, duration: 1, delay: .5, ease: Power2.easeOut });
  }

  static hide(__call) {

  }
}


