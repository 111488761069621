import gsap, { Power1 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    _container;
    static progress;
    static number;
    static percentage;
    static text;

    static init() {
        this._container = GetBy.id('Preloader');
        this.progress = GetBy.class('__progress', this._container);
        this.number = GetBy.class('__number', this._container)[0];
        this.text = GetBy.class('progress', this._container)[0];
    }

    static update(__progress) {
        gsap.killTweensOf(this.percentage);
        gsap.to(this.progress, {
            scaleX: __progress,
            ease: Power1.easeOut,
            duration: .1,
            onUpdate: () => {
                let number = Math.ceil(__progress);
                if (number < 10) number = '0' + number;

                this.number.innerHTML = `${number}%`;
            },
        });
    }

    static hide(__call) {
        if (isDebug) {
            gsap.set(this._container, {
                opacity: 0,
                onComplete: () => {
                    this._container.style.display = 'none';
                    if (__call) __call();
                }
            });
        } else {
            gsap.killTweensOf(this.progress);

            gsap.to(this, {
                percentage: 100,
                duration: .6,
                onUpdate: () => {
                    let number = Math.ceil(this.percentage);
                    if (number < 10) number = '0' + number;

                    this.number.innerHTML = `${number}%`;
                    gsap.set(this.progress, { scaleX: this.percentage / 100 });
                }
            });

            gsap.to(this.text, {
                duration: .5,
                opacity: 0,
                delay: 1,
                onComplete: () => {
                    this._container.style.display = 'none';
                    if (__call) __call();
                }
            });
            gsap.to(this._container, {
                duration: .5,
                opacity: 0,
                delay: 1.5,
                onComplete: () => {
                    this._container.style.display = 'none';
                    if (__call) __call();
                }
            });
        }
    }
}
