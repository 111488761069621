import gsap from 'gsap';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import PlayerVideo from '../component/PlayerVideoFull';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__PlayerVideo extends VScroll_Item {
  player;
  video;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.player = new PlayerVideo(__link);

    this.video = GetBy.class('player-video', __link)[0];
    gsap.set(this.video, { opacity: 0 });

    this.onShow = () => {
      gsap.to(this.video, { opacity: 1, duration: 1 });
    };
    this.onHide = () => {
      this.player.stop();
    };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  dispose() {
    super.dispose()
    if (this.player) this.player.dispose();
  }
}

Scroll._registerClass('block-player-video', ScrollItem__PlayerVideo);