import { GetBy } from "../_app/cuchillo/core/Element";

export default class MasonryLayout {
    initialized = false;

    constructor(containerSelector, itemSelector) {
        this.container = GetBy.class(containerSelector)[0];
        this.items = [...GetBy.class(itemSelector)];

        this.calcSizes();
        this.reorder();

        this.initialized = true;
    }

    calcSizes() {
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            const img = GetBy.selector('img', item)[0];
            item.style = '';
            img.style = '';

            const { width, height } = img.getBoundingClientRect();
            item.style.width = `${width}px`;
            item.style.height = `${height}px`;
        }


        this.columnWidth = this.items[0].offsetWidth;

        if (this.container.offsetWidth > 0) {
            this.columns = Math.floor(this.container.offsetWidth / this.columnWidth);
            this.columnHeights = new Array(this.columns).fill(0);

            const containerWidth = this.container.offsetWidth;
            const totalGapHorizontal = containerWidth - (this.columnWidth * this.columns);
            this.gap = totalGapHorizontal / (this.columns - 1);
        }
    }

    reorder() {
        this.items.forEach(item => {
            const minHeight = Math.min(...this.columnHeights);
            const columnIndex = this.columnHeights.indexOf(minHeight);

            const leftOffset = columnIndex * (this.columnWidth + this.gap);
            const topOffset = this.columnHeights[columnIndex];

            item.style.position = 'absolute';
            item.style.left = `${leftOffset}px`;
            item.style.top = `${topOffset}px`;

            this.columnHeights[columnIndex] += item.offsetHeight + this.gap;
        });

        this.container.style.height = `${Math.max(...this.columnHeights) - this.gap}px`;
    }

    resize() {
        if (!this.initialized) return;

        this.calcSizes();
        this.reorder();
    }
}
