import gsap, { Power2 } from 'gsap';

import { Videos } from '../_app/cuchillo/components/Videos';
import { Basics, isDebug, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Header from '../layout/Header';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Scene from '../3D/Scene';
import MusicController from '../controllers/MusicController';
import { GetBy } from '../_app/cuchillo/core/Element';
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';

export default class Default extends Page {
  enhancer;
  enhancerBtn;
  continueBtnBtn;

  constructor() {
    super();

    Videos.init();
  }

  enhanceClick() {
    this.enhancerBtn.removeEventListener(Basics.clickEvent, this._enhance);
    this.continueBtn.removeEventListener(Basics.clickEvent, this._continue);

    gsap.to(this.enhancer, {
      opacity: 0,
      duration: .6,
      ease: Power2.easeOut,
      onComplete: () => {
        Wrap.show(() => { this.afterShow() });
        this.enhancer.remove();
        MusicController.play();
      }
    });
  }

  continueClick() {
    this.enhancerBtn.removeEventListener(Basics.clickEvent, this._enhance);
    this.continueBtn.removeEventListener(Basics.clickEvent, this._continue);

    gsap.to(this.enhancer, {
      opacity: 0,
      duration: .6,
      ease: Power2.easeOut,
      onComplete: () => {
        Wrap.show(() => { this.afterShow() });
        this.enhancer.remove();
      }
    });
  }

  manageAudio() {
    this.enhancer = GetBy.id('Enhance');
    this.enhancerBtn = GetBy.selector('button', this.enhancer)[0];
    this.continueBtn = GetBy.selector('button', this.enhancer)[1];

    this._enhance = () => this.enhanceClick();
    this._continue = () => this.continueClick();
    this.enhancerBtn.addEventListener(Basics.clickEvent, this._enhance);
    this.continueBtn.addEventListener(Basics.clickEvent, this._continue);
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) {
      WebGLSketch.start();
      Scene.start();
      this.manageAudio();
    }
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Preloader.hide(() => {
        Scene.show(() => {

          if (isDebug) {
            Wrap.show(() => { this.afterShow() });
          } else gsap.to(this.enhancer, {
            opacity: 1,
            duration: .6,
            delay: 1.5,
            onComplete: () => {
              this.enhancer.style.pointerEvents = 'initial';
            }
          });

          // gsap.to(this.enhancer, {
          //   opacity: 1, duration: .6, delay: 1.5, onComplete: () => {
          //     this.enhancer.style.pointerEvents = 'initial';
          //   }
          // });
        });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();

    Header.show();
    Scroll.show();
    Scroll.start();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    Scroll.goto(0);
    super.beforeHide();
  }

  hide__effect() {
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.hide();
    super.afterHide();
  }
}

ControllerPage._addPage('default', Default);
