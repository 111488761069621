import gsap, { Power2 } from 'gsap';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import PlayerVideo from '../component/PlayerVideoFull';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__WidgetLanding extends VScroll_Item {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    const opts = {
      height: 2
    };

    if (!isMobile) __link.style.setProperty('--height', 2);

    this.onShow = () => {
      if (isMobile) return;

      gsap.to(opts, {
        height: 1,
        duration: 1.5,
        ease: Power2.easeInOut,
        onUpdate: () => {
          __link.style.setProperty('--height', opts.height);
        }
      });
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('WidgetLanding', ScrollItem__WidgetLanding);
