import gsap, { Power3, Power4 } from 'gsap';

import { Maths } from '../_app/cuchillo/utils/Maths';
import WebGLGroup from '../_app/cuchillo/3D/WebGLGroup';
import WebGLSketch from '../_app/cuchillo/3D/WebGLSketch';
import { GetBy } from '../_app/cuchillo/core/Element';
import { RIPPLE_GEOMETRY, RIPPLE_MATERIAL, RIPPLE_SETTINGS } from './ripple-material';
import Wave from './Wave';
import DebugPane from './DebugPane';
import { Ease } from '../_app/cuchillo/utils/Ease';
import MusicController from '../controllers/MusicController';
import { AmbientLight, DirectionalLight, Group } from 'three';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export default class Scene {
    static _running = false;
    static _initialized = false;
    static _tick = 0;

    static dom;
    static wave;

    static group = new Group();
    static positionGroup = new WebGLGroup({
        zFactor: .05,
    });
    static rotationGroup = new WebGLGroup({
        rotFactor: .025
    });

    static amp1 = 0;
    static freq1 = 0;
    static amp2 = 0;
    static freq2 = 0;
    static bass = 0;

    static init() {
        this.group.add(this.rotationGroup);
        this.positionGroup.add(this.group);
        WebGLSketch.add(this.positionGroup);
        this.init3dItems();
    }

    static start() {
        // DebugPane.init();
        if (!this._running) this._running = true;
    }

    static stop() {
        if (this._running) this._running = false;
    }

    static add(element) {
        this.rotationGroup.add(element);
    }

    static remove(element) {
        this.rotationGroup.remove(element);
    }

    static init3dItems() {
        this.dom = GetBy.selector('#scene-target span')[0];

        this.rotationGroup.rot.z = Maths.toRadians(-45);
        this.positionGroup.pos.z = 2500;

        this.wave = new Wave({
            dom: this.dom,
            geometry: RIPPLE_GEOMETRY.clone(),
            material: RIPPLE_MATERIAL.clone()
        });

        this.wave.init().then(() => {
            this.add(this.wave);
        });
    }

    static show(cb = () => { }) {
        let delay = 0;

        gsap.to(this.wave.material.uniforms.u_transparency, {
            value: 2,
            duration: 5,
            ease: Ease.EASE_CUCHILLO_OUT,
            delay
        });

        gsap.to(this.positionGroup.pos, { z: 0, duration: 3.5, ease: Ease.EASE_CUCHILLO_OUT, delay });
        gsap.to(this.rotationGroup.rot, { z: 0, duration: 2.5, ease: Ease.EASE_CUCHILLO_OUT, delay });

        delay += .3;
        const rotX = Maths.toRadians(RIPPLE_SETTINGS.rotX);
        const rotY = Maths.toRadians(RIPPLE_SETTINGS.rotY);
        const rotZ = Maths.toRadians(RIPPLE_SETTINGS.rotZ);
        gsap.to(this.group.rotation, { x: rotX, duration: 2, ease: Ease.EASE_CUCHILLO_OUT, delay });
        gsap.to(this.group.rotation, { z: rotZ, duration: 2, ease: Ease.EASE_CUCHILLO_OUT, delay });
        gsap.to(this.group.rotation, {
            y: rotY, duration: 2, ease: Ease.EASE_CUCHILLO_OUT, delay,
            onComplete: () => {
                this._initialized = true;
                cb();
            }
        });
    }

    static hide() { }

    static loop() {
        this.positionGroup.update();
        this.rotationGroup.update();

        if (!this._running || !this._initialized) return;

        // POINT SIZE
        this.wave.material.uniforms.u_pointsize.value = RIPPLE_SETTINGS.u_pointsize;

        let freq1 = 0;
        let amp1 = 0;
        let freq2 = 0;
        let amp2 = 0;
        let tick = .5;

        if (MusicController.isPlaying) {
            const { mid, treble, bass } = MusicController.analyzeMusic();

            tick += bass;
            freq1 += treble;
            amp1 += mid;
            freq1 += treble;
            amp1 += mid;

            // console.log(bass);
        }

        // WAVE SPEED
        this.bass = Maths.lerp(this.bass, tick, .024);
        this._tick += this.bass;
        this.wave.material.uniforms.u_time.value = this._tick * .04;

        // WAVE 1
        this.freq1 = Maths.lerp(this.freq1, RIPPLE_SETTINGS.u_noise_freq_1 + freq1, .02);
        this.amp1 = Maths.lerp(this.amp1, RIPPLE_SETTINGS.u_noise_amp_1 + amp1, .02);
        this.wave.material.uniforms.u_noise_freq_1.value = this.freq1;
        this.wave.material.uniforms.u_noise_amp_1.value = this.amp1;

        // WAVE 2
        this.freq2 = Maths.lerp(this.freq2, RIPPLE_SETTINGS.u_noise_freq_2 + freq2, .008);
        this.amp2 = Maths.lerp(this.amp2, RIPPLE_SETTINGS.u_noise_amp_2 + amp2, .008);
        this.wave.material.uniforms.u_noise_freq_2.value = this.freq2;
        this.wave.material.uniforms.u_noise_amp_2.value = this.amp2;

        const p = 250;
        const z = Maths.lerp(0, p, Scroll.progress());
        this.positionGroup.pos.z = z;

        // // Lights
        // if (!isSmartphone) {
        //     const { x: lightX, y: lightY } = Functions.domPositionTo3D(Interaction.positions.mouse.x, Interaction.positions.mouse.y);
        //     this.mouseLightTarget.set(lightX, lightY, 0);
        //     this.mouseLight.target.position.lerp(this.mouseLightTarget, LIGHT_SETTINGS.lerp);

        //     this.lightColor.set(LIGHT_SETTINGS.color);
        //     this.mouseLight.color = this.lightColor;

        //     this.mouseLight.intensity = LIGHT_SETTINGS.intensity;
        //     this.mouseLight.angle = Maths.toRadians(LIGHT_SETTINGS.angle);
        //     this.mouseLight.penumbra = LIGHT_SETTINGS.penumbra;
        //     this.mouseLight.decay = LIGHT_SETTINGS.decay;

        //     this.mouseLight.visible = LIGHT_SETTINGS.visible;
        // }

        // // Group rotation with mouse
        // if (!isTouch) {
        //     // Mouse centered on target
        //     // const { x, y, width, height } = this.dom.getBoundingClientRect();
        //     // const centerX = x + width * .5;
        //     // const centerY = y + height * .5;
        //     const center = {
        //         x: Interaction.positions.mouse.x - Metrics.CENTER_X,
        //         y: Interaction.positions.mouse.y - Metrics.CENTER_Y
        //     };

        //     // Group rotation
        //     let mx = Maths.map(center.x, - Metrics.CENTER_X, Metrics.CENTER_X, -SETTINGS.mouseX, SETTINGS.mouseX);
        //     let my = - Maths.map(center.y, - Metrics.CENTER_Y, Metrics.CENTER_Y, -SETTINGS.mouseY, SETTINGS.mouseY);
        //     this.mouse.x = Maths.lerp(this.mouse.x, mx, SETTINGS.mouseLerp);
        //     this.mouse.y = Maths.lerp(this.mouse.y, my, SETTINGS.mouseLerpY);

        //     const rotationX = Maths.toRadians(this.mouse.y);
        //     const rotationY = Maths.toRadians(this.mouse.x);
        //     this.rotationGroup.rot.x = -rotationX;
        //     this.rotationGroup.rot.y = rotationY;

        //     // console.log(center.x, mx);

        //     const posX = Maths.map(center.x, - Metrics.CENTER_X, Metrics.CENTER_X, -SETTINGS.posX, SETTINGS.posX);
        //     const posY = Maths.map(center.y, - Metrics.CENTER_Y, Metrics.CENTER_Y, -SETTINGS.posY, SETTINGS.posY);
        //     const dist = Math.abs(0 - center.x);
        //     const posZ = Maths.map(dist, 0, Metrics.WIDTH / 2, SETTINGS.posZ, 0);
        //     this.rotationGroup.pos.x = -posX;
        //     this.rotationGroup.pos.y = posY;
        //     this.rotationGroup.pos.z = -posZ;

        //     this.rotationGroup.update();
        // }
    }

    static resize() {
        if (this.wave) this.wave.resize();
    }

    static dispose() {
        if (this.wave) {
            this.remove(this.wave);
            this.wave.dispose();
        }

        WebGLSketch.remove(this.group);
        this.group.dispose();
    }
}
