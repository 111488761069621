import { Points } from 'three';

import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { Sizes } from '../_app/cuchillo/core/Sizes';

export default class Wave extends WebGLObject {
    constructor(opts = {}) {
        super(opts);
    }

    init() {
        return new Promise((resolve, reject) => {
            resolve();

            this.mesh = new Points(this.geometry, this.material);
            this.add(this.mesh);
            this.visible = true;

            this.resize();
        });
    }

    // update() {
    //     if (!this.visible) return;
    // }

    calculateResolution() {
        const width = this.size.x;
        const height = this.size.y;

        const planeAspect = this.mesh.scale.y / this.mesh.scale.x;
        let a1;
        let a2;

        if (height / width > planeAspect) {
            const h = (width / height) * planeAspect;
            // Canvas more horizontal than image
            a1 = 1;
            a2 = h;
        } else {
            // Canvas more vertical than image
            a1 = height / width / planeAspect;
            a2 = 1;
        }
        return {
            x: a1,
            y: a2
        };
    }

    resize() {
        const { width, height } = this.dom.getBoundingClientRect();

        let size = width;
        if (height > width) size = height;

        this.size.x = size;
        this.size.y = size;

        super.resize(size, size);

        this.material.uniforms._resolution.value = 1;
    }
}